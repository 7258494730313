import { graphql, navigate } from "gatsby"
import React, { useMemo } from "react"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import LayoutUpdate from "../../components/layout/layout-update"
import SEOnew from "../../components/seo-new"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/zoom"
import "swiper/css/navigation"

import "./press-details-styles.css"

// import required modules
import { Navigation, Autoplay, Zoom, Keyboard } from "swiper/modules"
import { PrismicRichText } from "@prismicio/react"
import htmlSerializer from "../../utils/htmlSerializer"
import ContactUsCompact from "../../components/sections/ContactUsCompact"
import CommitmentSignature from "../../components/sections/CommitmentSignature"
import { HiArrowLeft } from "react-icons/hi"
import { StaticImage } from "gatsby-plugin-image"

const PressDetailsPage = ({ data }) => {
  const prismicPressData = data.prismicPress.data

  const gallery = useMemo(() => {
    const images = prismicPressData.gallery.map(
      gallery => gallery.image1.fluid.src
    )

    return images
  }, [prismicPressData])
  return (
    <LayoutUpdate>
      <SEOnew title={prismicPressData.title} />
      <div className="w-full flex justify-center">
        <div className="press-details pt-8 lg:pt-16 px-4 lg:px-24 max-w-7xl w-full">
          <h1 className="text-lg lg:text-3xl text-center">
            {prismicPressData.title}
          </h1>
          <Swiper
            navigation={true}
            keyboard={{
              enabled: true,
            }}
            // autoplay={{
            //   delay: 4000,
            //   pauseOnMouseEnter: true,
            //   disableOnInteraction: false,
            // }}
            zoom={true}
            speed={800}
            loop={true}
            className="press-swiper"
            // modules={[Navigation, Autoplay, Zoom, Keyboard]}
            onZoomChange={swiper => {
              swiper.autoplay.stop()
            }}
            onSlideNextTransitionEnd={swiper => {
              swiper.autoplay.start()
              swiper.autoplay.resume()
            }}
            onSlidePrevTransitionEnd={swiper => {
              swiper.autoplay.start()
              swiper.autoplay.resume()
            }}
          >
            {gallery.map((img, index) => {
              return (
                <SwiperSlide
                  key={`customer-details-gallery-${index}`}
                  className="relative"
                >
                  <StaticImage
                    id="cover"
                    src="../../images/blank.png"
                    quality={95}
                    placeholder="DOMINANT_COLOR"
                    formats={["AUTO", "WEBP"]}
                    alt={`press-${index}`}
                    className="w-full h-full z-5 top-0 left-0"
                    objectFit="cover"
                    style={{position: "absolute"}}
                  />
                  <div className="swiper-zoom-container">
                    <img src={img} loading="lazy" alt={`press-${index}`} />
                  </div>
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
                </SwiperSlide>
              )
            })}
          </Swiper>
          <PrismicRichText
            field={prismicPressData.press_details_content.richText}
            components={htmlSerializer}
          />
          <div
            className="flex items-center gap-x-2 cursor-pointer pt-16 pb-16"
            onClick={() => {
              navigate("/press")
            }}
          >
            <HiArrowLeft />
            <p className="text-sm">Back to Press</p>
          </div>
        </div>
      </div>
    </LayoutUpdate>
  )
}

export default withPrismicPreview(PressDetailsPage)

export const pressTemplateQuery = graphql`
  query($id: String!) {
    prismicPress(id: { eq: $id }) {
      _previewable
      uid
      data {
        title
        gallery {
          image1 {
            fluid {
              src
            }
          }
        }
        press_details_content {
          richText
        }
      }
    }
  }
`
